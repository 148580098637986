import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';

import classes from './App.module.css';
import PosMachine from './components/Section/PosMachine';
import Video from './components/Section/Video';
import SignupForm from './components/Section/SignupForm';
import SuccessPage from './components/Section/PaymentSuccess';
import CancelPage from './components/Section/PaymentCancel';

function App() {
  return (
    <Router>
      <div className={classes['app-container']}>
        <Switch>
          <Route path="/success">
            <SuccessPage />
          </Route>
          <Route path="/cancel">
            <CancelPage />
          </Route>
          <Route path="/">
            <section>
              <PosMachine />
            </section>
            <section>
              <Video />
            </section>
            <section>
              <SignupForm />
            </section>
          </Route>
        </Switch>
      </div>
    </Router>
  );  
}

export default App;
