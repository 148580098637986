import axios from 'axios';
import { API_URL } from '../utils/constants';

async function signUp(data) {
  try {
    if (!data || !data.endPoint || data.endPoint === '') {
      alert('Invalid endpoint');
      return;
    }
    const response = await axios.post(API_URL + data.endPoint, data);
    return response.data;
  } catch (error) {
    console.error('Error creating post:', error);
    throw error;
  }
}

export { signUp };




// import axios from 'axios';
// import { API_URL } from '../utils/constants';

// async function getCsrfToken() {
//   try {
//     const response = await fetch(API_URL + 'get-csrf-token/', {
//       credentials: 'include' // Include cookies in the request
//     });
//     if (response.ok) {
//       const { csrfToken } = await response.json(); // Extract the csrfToken from the response
//       return csrfToken;
//     } else {
//       console.error('Failed to fetch CSRF token:', response.status);
//       throw new Error('Failed to fetch CSRF token');
//     }
//   } catch (error) {
//     console.error('Error fetching CSRF token:', error);
//     throw error;
//   }
// }

// async function signUp(data) {
//   try {
//     const csrfToken = await getCsrfToken(); // Get the CSRF token
//     const headers = {
//       'Content-Type': 'application/json',
//       'X-CSRFToken': csrfToken
//     };
//     const response = await axios.post(API_URL + 'payments/', data, { headers });
//     return response.data;
//   } catch (error) {
//     console.error('Error creating post:', error);
//     throw error;
//   }
// }

// export { signUp };
