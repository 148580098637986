import React, { useEffect, useState } from 'react';
import { signUp } from '../../services/postService';
import classes from './PaymentSuccess.module.css';

import CompanyLogo from '../../assets/company-logo.png';

const PaymentSuccess = () => {
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null); // New state to track payment status
  const paymentId = localStorage.getItem('paymentId');
  const userData = {
    endPoint: 'payment/update/',
    paymentId: paymentId,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await signUp(userData);
        setIsDataFetched(true);
        setPaymentStatus(response.code);
      } catch (error) {
        setIsDataFetched(true);
        setPaymentStatus(500); // Set a default status for error case
      }
    };

    if (!isDataFetched) {
      fetchData();
    }
  }, []); 

  useEffect(() => {
    return () => {
      localStorage.removeItem('paymentId');
    };
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {isDataFetched && paymentStatus === 200 ? (
          <>
            <h1 className={classes.title}>Payment Success!</h1>
            <p className={classes.description}>Thank you for subscribing to SmartPOS monthly subscription.</p>
            <img src={CompanyLogo} alt="Company Logo" className={classes.logo} />
          </>
        ) : isDataFetched && paymentStatus !== 200 ? (
          <>
            <h1 className={classes['error-title']}>Payment Error</h1>
            <p className={classes['error-description']}>Oops! An error occurred while processing your payment.</p>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default PaymentSuccess;
