import classes from './PosMachine.module.css';
import posBanner from '../../assets/pos-banner.png';


const PosMachine = () => {
    const posMachineFeatures = [
        'Touchscreen interface',
        'Barcode scanner',
        'Receipt printer',
        'Cash drawer',
        'Card reader',
        'Inventory management',
        'Sales reporting',
        'Integration with payment gateways',
        'Customer loyalty program',
    ];

  return (
    <div className={classes.container}>
      <div className={classes.column}>
        <img className={classes['pos-machine-image']} src={posBanner} alt="POS Machine" />
      </div>
      <div className={classes.column}>
        <h2>Features of POS Machine</h2>
        <ul>
          {posMachineFeatures.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PosMachine;
