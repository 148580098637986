import React, { useState } from 'react';
import classes from './SignupButton.module.css';
import { signUp } from '../../services/postService';

const SignupButton = () => {
    const [isProcessing, setIsProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const userData = {
        endPoint: 'payments/',
    } 
       
    const handlePayment = async () => {
        try {
            console.log('handlePayment')
            setIsProcessing(true); // Set processing state to true during the API request
            const response = await signUp(userData);
            const checkoutUrl = response.data.attributes.checkout_url;
            const paymentId = response.data.id;

            localStorage.setItem('paymentId', paymentId);

            // Redirect to the checkout URL
            window.location.href = checkoutUrl;
        } catch (error) {
            console.error('Error creating post:', error);
            setErrorMessage('Failed to initiate payment. Please try again later.');
        } finally {
            setIsProcessing(false); // Set processing state back to false after the API request completes
        }
    };

    return (
        <div>
            {errorMessage && <p className={classes['error-message']}>{errorMessage}</p>}
            <button className={classes['signup-button']} onClick={handlePayment} disabled={isProcessing}>
                {isProcessing ? 'Processing...' : 'Sign Up'}
            </button>
        </div>
    );
};

export default SignupButton;
