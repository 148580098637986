import classes from './Video.module.css';


const Video = () => {
    const youtubeVideoId = 'inJiTDd6zzM';
    
    return (
        <div className={classes['video-container']}>
            <h2>Video Section</h2>
            <div className={classes['video-wrapper']}>
            <iframe
                title="YouTube Video"
                src={`https://www.youtube.com/embed/${youtubeVideoId}`}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
            </div>
        </div>
    );
};

export default Video;
