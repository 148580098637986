import React from 'react';

import SignupButton from './SignupButton';
import classes from './SignupForm.module.css';

const SignupForm = () => {
  return (
    <div className={`${classes.container} ${classes['centered-text']}`}>
      <h2>Subscribe @ Php500/month only!</h2>
      <SignupButton />
    </div>
  );
};

export default SignupForm;
