import React from 'react';
import classes from './PaymentCancel.module.css';

const PaymentCancel = () => {
  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Payment Cancelled</h1>
      <p className={classes.content}>We're sorry, but your payment has been cancelled.</p>
    </div>
  );
};

export default PaymentCancel;
